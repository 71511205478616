<template>
  <table class="nk-tb-list" :class="{'is-separate' : undefined !== separated}">
    <slot></slot>
  </table>
</template>

<script>
export default {
  props:{
    separated: String,
  }
}
</script>
