<template>
  <td class="nk-tb-col" :class="classesList" :colspan="colSpan">
    <slot></slot>
  </td>
</template>

<script>
import {computed} from "vue";

export default {
  name: "TbTd",
  props: {
    colSpan: Number,
    size: String,
    breakPoint: String,
  },
  setup(props){
    const classesList = computed(()=>{
      let c = []
      if(props.breakPoint){
        c.push(`tb-col-${props.breakPoint}`)
      }
      return c;
    })
    return {
      classesList
    }
  }
}
</script>
