<template>
  <th class="nk-tb-col" :class="classesList">
    <slot></slot>
  </th>
</template>

<script>
import {computed} from "vue";

export default {
  name: "TbTh",
  props: {
    breakPoint: String
  },
  setup(props){
    const classesList = computed(()=>{
      let c = []
      if(props.breakPoint){
        c.push(`tb-col-${props.breakPoint}`)
      }
      return c;
    })
    return {
      classesList
    }
  }
}
</script>
