<template>
  <tr class="nk-tb-item" :class="classesList">
    <slot></slot>
  </tr>
</template>

<script>
export default {
  props: {
    headRow: Boolean
  },
  setup(props){
    let classesList = []
    if(props.headRow){
      classesList.push('nk-tb-head')
    }
    return {
      classesList,
    }
  }
}
</script>
