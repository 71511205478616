<template>
  <page-template>
    <main-block>
      <block-head :title="$t('Assigned')+ ' Leads'" :description="$t('Total')+' '+pagination.totalRecords+' leads.'"></block-head>
      <block-content>
        <card no-padding stretch>
          <template v-slot:grouped>
            <div class="card-inner position-relative card-tools-toggle">
              <card-header title="">
                <card-tools>
                  <div class="form-inline flex-nowrap gx-3">
                    <div class="form-wrap w-150px">
                      <select class="form-select form-select-md form-control" v-model="bulkSelectedAction">
                        <option value="">{{$t('Bulk Action')}}</option>
                        <option value="withdraw">{{$t('Withdraw')}}</option>
                      </select>
                    </div>
                    <div class="btn-wrap">
                          <span class="d-none d-md-block">
                            <nk-button dim outline type="light" v-on:click="bulkAction" :disabled="!bulkSelectedAction.length">{{$t('Apply')}}</nk-button>
                          </span>
                      <span class="d-md-none">
                            <nk-button dim outline type="light" v-on:click="bulkAction" is-icon-only-button="" :disabled="!bulkSelectedAction.length">
                              <nio-icon icon="ni-arrow-right"></nio-icon>
                            </nk-button>
                          </span>
                    </div>
                  </div>
                </card-tools>
                <card-tools class="mr-n1">
                  <btn-toolbar>
                    <btn-toolbar-toggle-wrap id="advisors_toolbar">
                      <li>
                        <nk-button is-link type=""
                                   is-icon-only-button=""
                                   class="search-toggle"
                                   v-on:click="searchBarActive=true"
                                   :class="{active: searchBarActive}">
                          <nio-icon icon="ni-search"></nio-icon>
                        </nk-button>
                      </li>
                      <li class="btn-toolbar-sep"></li>
                      <li>
                        <drop-down
                            icon="ni-filter-alt"
                            size="lg"
                            direction="right"
                            :dot="( filters.status !== '' || filters.date !== '' || filters.has_notes ? 'primary':'')"
                            :title="'Leads '+ $t('Filter')">
                          <template v-slot:body>
                            <row class="gx-6 gy-3">
                              <column default="12" md="6">
                                <label class="overline-title overline-title-alt" for="d_form_f">{{$t('Form')}}</label>
                                <b-select v-model="filters.form" id="d_form_f">
                                  <option value="">{{$t('All')}}</option>
                                  <option value="1">{{$t('Other')}}</option>
                                </b-select>
                              </column>
                              <column default="12" md="6">
                                <label class="overline-title overline-title-alt" for="d_status_f">{{$t('Status')}}</label>
                                <b-select v-model="filters.status" id="d_status_f">
                                  <option value="">{{$t('All')}}</option>
                                  <option v-for="status in leadStatuses" :key="'ls'+status.id" :value="status.id">{{ $t(status.status_text) }}</option>
                                </b-select>
                              </column>
                              <column default="12" class="mt-1">
                                <label class="overline-title overline-title-alt d-flex align-items-center" for="d_hv_notes_f">
                                  <b-check-box id="d_hv_notes_f" v-model="filters.has_notes" class="mr-2"></b-check-box> {{$t('Has')+' '+$t('Notes')}}
                                </label>
                              </column>
                            </row>
                          </template>
                          <template v-slot:footer>
                            <nk-button type="dark" dim v-on:click="getLeads(true)">{{$t('Filter')}}</nk-button>
                            <a class="clickable" href="javascript:;" v-on:click="[filters.status=filters.date='',filters.has_notes=false,getLeads(true)]">{{$t('Reset Filter')}}</a>
                          </template>
                        </drop-down>
                      </li><!-- li -->
                      <li>
                        <drop-down icon="ni ni-sort" title="" size="xs" direction="right">
                          <ul class="link-check">
                            <li><span>{{$t('Order')}}</span></li>
                            <li :class="{active: filters.display_order === 'asc'}"><a href="javascript:;" v-on:click="[filters.display_order='asc', getLeads(true)]">{{$t('ASC')}}</a></li>
                            <li :class="{active: filters.display_order === 'desc'}"><a href="javascript:;" v-on:click="[filters.display_order='desc', getLeads(true)]">{{$t('DESC')}}</a></li>
                            <li :class="{active: filters.display_order === 'top_rated'}"><a href="javascript:;" v-on:click="[filters.display_order='top_rated', getLeads(true)]">{{$t('Top Rated')}}</a></li>
                          </ul>
                        </drop-down>
                      </li><!-- li -->
                    </btn-toolbar-toggle-wrap>
                  </btn-toolbar>
                </card-tools>
              </card-header>
              <card-search
                  :placeholder="$t('Search: lead number/id, advisor name/email')"
                  :is-active="searchBarActive"
                  @do-search="doSearch"
                  :search-term="filters.search"
                  @close-search="[searchBarActive=false, doSearch('')]"></card-search>
            </div>
            <card-inner v-if="advisorsForFiltering.length">
              <div class="fw-500">{{ $t('Show assigned leads per advisor') }}:</div>
              <div class="d-flex py-1" style="overflow-x: auto;">
                <div v-for="(adv,i) in advisorsForFiltering" :key="'adv_f'+adv.id"
                     class="d-flex align-items-center border py-1 px-2 rounded-pill medium no_select cursor-pointer mr-1"
                     :class="{'ml-2': i > 0, 'bg-primary-dim': adv.id === filters.advisor}"
                     v-on:click="[(filters.advisor = adv.id === filters.advisor ? '' : adv.id), getLeads(true, true)]" style="flex-shrink: 0;">
                  <span class="fw-500">{{ adv.first_name+' '+adv.last_name}}</span>
                  <div class="lh-n ml-2 small nk-text-muted">
                    <span class="fw-500">{{ $t('Paid')}} :</span> {{ adv?.leads?.paid  || 0}}<br>
                    <span class="fw-500">{{ $t('Free')}} :</span> {{ adv?.leads?.free || 0}}<br>
                    <span class="fw-500">{{ $t('Total')}}</span> : {{ (adv?.leads?.paid + adv?.leads?.free) || 0 }}<br>
                  </div>
                </div>
              </div>
            </card-inner>
            <card-inner>
              <div class="d-flex">
                <div class="fw-500">{{ $t('Display') }}:</div>
                <template v-for="(tmp,tmpX) in [{k: 'this_week', v: 'This week'},{k: 'prev_week', v: 'Prev week'}]"
                          :key="'ldf'+tmp.k">
                  <nk-button size="xs"
                             :type="filters.time_filter === tmp.k ? 'primary' : 'light'" outline class="text-uppercase"
                             :class="[tmpX > 0 ? 'ml-1' : 'ml-2']"
                             v-on:click="filters.time_filter=(filters.time_filter === tmp.k ? '' : tmp.k)">{{$t(tmp.v)}}</nk-button>
                </template>
                <drop-down
                    size="lg"
                    direction="right"
                    :trigger-btn-text="$t('Date Range').toUpperCase()+(filters.time_filter==='date_range' ? ' '+filters.date : '')"
                    :title="$t('Date Range')"
                    :trigger-btn-classes="'btn-xs ml-1 btn-outline-'+(filters.time_filter==='date_range' ? 'primary' : 'light')">
                  <template v-slot:body>
                    <row class="gx-6 gy-3">
                      <column default="12">
                        <label class="overline-title overline-title-alt" for="d_range_f">{{$t('Date Range')+' '+$t('Filter')}}</label>
                        <vue-flatpickr v-model="filters.date" id="d_range_f" class="form-control" :config="vueFlatPickerConfig"></vue-flatpickr>
                      </column>
                    </row>
                  </template>
                  <template v-slot:footer>
                    <nk-button type="dark" dim v-on:click="[(filters.date ? filters.time_filter='date_range' : '')]">{{$t('Filter')}}</nk-button>
                    <a href="javascript:;" v-on:click="[filters.time_filter='', filters.date = '']">{{$t('Reset')}}</a>
                  </template>
                </drop-down>
              </div>
            </card-inner>
            <div class="d-flex py-5 justify-center"  v-if="loadingData">
              <ion-spinner></ion-spinner>
            </div>
            <template v-else>
              <div class="card-inner p-0">
                <list-table>
                  <tb-row head-row>
                    <tb-th has-check-box>
                      <b-check-box id="asl_select_all" v-model="selectAll"></b-check-box>
                    </tb-th>
                    <tb-th break-point="md">
                      <span class="sub-text">Lead#</span>
                    </tb-th>
                    <tb-th>
                      <span class="sub-text">{{$t('Assigned to')}}</span>
                    </tb-th>
                    <tb-th break-point="md">
                      <nio-icon icon="ni-notes-alt" class="mr-1" style="font-size: 1.1rem;" v-tooltip="$t('Notes')"></nio-icon>
                    </tb-th>
                    <tb-th break-point="md">Lead</tb-th>
                    <tb-th break-point="md">{{$t('Assign date')}}</tb-th>
                    <tb-th break-point="lg">{{$t('Opened')}}</tb-th>
                    <tb-th break-point="lg">{{$t('Current status')}}</tb-th>
                    <tb-th class="nk-tb-col-tools"></tb-th>
                  </tb-row>

                  <template v-for="lead in leads" :key="'ld'+lead.id">
                    <tb-row>
                      <tb-td has-check-box>
                        <b-check-box :id="'lead'+lead.id" v-model="lead.selected" :key="'cb'+lead.id"></b-check-box>
                      </tb-td>
                      <tb-td break-point="md" class="lh-n">
                        {{ lead.lead_number }}
                        <star-rating :rating="parseFloat(lead.rating)" :increment="0.5" :star-size="12" :show-rating="false" :read-only="true"></star-rating>
                      </tb-td>
                      <tb-td>
                        <user-small-card
                            :user-name="lead.advisor_f_name+' '+lead.advisor_l_name"
                            :user-email="lead.advisor_email" :image="lead.advisor_dp" :initials="lead.advisor_initials">
                          <template v-slot:more_info>
                            <div class="lh-n d-block d-md-none"><b>Lead #:</b> {{ lead.lead_number}}</div>
                          </template>
                        </user-small-card>
                      </tb-td>
                      <tb-td break-point="md">
                        <a href="javascript:;" v-if="lead.notes_count > 0" v-on:click="getLeadNotes(lead.AL_id)" class="d-flex align-items-center">
                          <nio-icon icon="ni-notes-alt" class="mr-1" style="font-size: 1.1rem;"></nio-icon>
                          <span class="text-primary">{{lead.notes_count}}</span>
                        </a>
                      </tb-td>
                      <tb-td break-point="md">
                        <span class="tb-lead">{{ lead.first_name }}</span>
                        {{ lead.last_name }}
                      </tb-td>
                      <tb-td break-point="md">{{ lead.assign_date }}</tb-td>
                      <tb-td break-point="lg">{{ lead.opened_time }}</tb-td>
                      <tb-td break-point="lg">{{ $t(lead.status_text) }}</tb-td>
                      <tb-td class="nk-tb-col-tools">
                        <ul class="nk-tb-actions gx-1">
                          <li>
                            <drop-down-list>
                              <list-item icon="ni-eye" :text="$t('View Details')" :to="{name: 'adminLeadDetails', params:{id:lead.id}}"></list-item>
                              <list-item icon="ni-curve-left-down" :text="$t('Withdraw')" v-on:click="F_withdrawLeads([lead.AL_id])"></list-item>
                            </drop-down-list>
                          </li>
                        </ul>
                      </tb-td>
                    </tb-row>
                    <tb-row v-for="(f,fI) in lead.followup_logs" :key="'lfl'+lead.AL_id+'_'+fI" class="bg-secondary-dim">
                      <tb-td colspan="9">
                        <row class="medium align-items-center">
                          <column default="auto" class="px-3 py-1 align-center">
                            <nio-icon icon="ni-curve-down-right" class="mr-1 h4 text-muted"></nio-icon>
                            <span>{{ $t('Attempt')+' '+(fI+1) }}</span>
                          </column>
                          <column default="auto">
                            {{ $t('Date')+': '+f.followup_date}}<br/>
                            {{ $t('Time')+': '+f.followup_time}}
                          </column>
                          <column default="auto">
                            <span class="fw-500 text-dark">{{$t(f.info?.title || '')}}</span>
                            <p class="text-muted lh-n" v-if="f.info?.translation_id">{{ $t( 'followup_logs.'+f.info?.translation_id || '', f.info?.translation_params || {}) }}</p>
                            <p class="text-muted lh-n" v-else>{{ $t(f.info?.description || '') }}</p>
                          </column>
                        </row>
                      </tb-td>
                    </tb-row>
                  </template>
                </list-table>

              </div>
              <div class="card-inner" v-if="pagination.totalRecords > 10">
                <pagination
                    v-model="pagination.page"
                    :records="pagination.totalRecords"
                    :per-page="10"
                    @paginate="paginationCallback"
                    theme="bootstrap4"
                    :options="{chunk: 5, texts:{count: '||'}}"/>
              </div>
            </template>
          </template>
        </card>
      </block-content>
    </main-block>
    <ion-modal
        :is-open="leadNotes.modalOpen"
        :css-class="isDesktop ? 'modal-web': ''"
        @didDismiss="leadNotes.modalOpen=false">
      <i-modal
          :title="$t('Lead')+' '+$t('Notes')"
          @modal-close="leadNotes.modalOpen=false">
        <div class="lead_note_single" v-for="(note, index) in leadNotes.notes" :key="'ln'+index">
          <div v-html="DOMPurify.sanitize(note.note)"></div>
          <div class="small mt-1 nk-text-dark">{{note.added_time}}</div>
        </div>
      </i-modal>
    </ion-modal>
  </page-template>
</template>

<script>

import {
  IonModal,
  IonSpinner,
  loadingController,
  toastController,
  isPlatform, onIonViewWillEnter
} from '@ionic/vue';
import {defineComponent, reactive, ref, watch} from 'vue';
import {BlockContent, BlockHead, MainBlock} from "@core/layouts";
import axios from "@/libs/axios"
import {Card, CardHeader, CardInner, CardSearch, CardTools} from "@core/components/cards";

import { BtnToolbar, BtnToolbarToggleWrap } from "@core/components/btn-toolbar"
import "bootstrap"
import DropDown from "@core/components/dropdown/DropDown";

import { Column, Row } from "@core/layouts"

import Pagination from "v-pagination-3"
import {BCheckBox, BSelect} from "@core/components/bp-form";
import UserSmallCard from "@/views/components/user-cards/UserSmallCard";
import {DropDownList, ListItem} from "@core/components/dropdown-list";
import { useStore } from "vuex"
import VueFlatpickr from "vue-flatpickr-component"
import {Dutch} from "flatpickr/dist/l10n/nl"
import 'flatpickr/dist/flatpickr.css';
import useDataChangeTracker from "@/store/data-change-tracker/dataChangeTracker"
import useAdminLeadFunctions from "@core/comp-functions/admin/AdminLeadFunctions"
import StarRating from "vue-star-rating";
import {useI18n} from "vue-i18n";
import IModal from "@core/components/ion-modal/IModal";
import DOMPurify from "dompurify";
import ListTable from "@core/components/list-table/ListTable";
import {TbTd, TbTh, TbRow} from "@core/components/data-tables";
import PageTemplate from "@core/layouts/page/PageTemplate";

export default defineComponent({
  components: {
    PageTemplate,
    TbTd,
    TbTh,
    TbRow,
    ListTable,
    CardInner,
    IModal,
    BSelect,
    ListItem,
    DropDownList,
    UserSmallCard,
    BCheckBox,
    CardSearch,
    CardTools,
    BlockHead,
    Column,
    Row,
    DropDown,
    VueFlatpickr,
    BtnToolbar, BtnToolbarToggleWrap,
    CardHeader,
    Card,
    BlockContent,
    MainBlock,
    IonModal,
    IonSpinner,
    Pagination,
    StarRating,
  },
  setup() {

    // Constants
    const{t}            = useI18n()
    const isDesktop     = isPlatform('desktop')
    const store         = useStore()
    const {updateTracker} = useDataChangeTracker()
    const leadStatuses  = store.state.general_data.leads_statuses
    const vueFlatPickerConfig = {
      altFormat: 'd/m/Y',
      altInput: true,
      mode: "range",
      time_24hr: true,
      locale: store.state.appConfig.lang === 'nl' ? Dutch: '',
    }
    const {advisorsLeadsCount, withdrawLeads} = useAdminLeadFunctions()

    // Reactive objects
    let filters = reactive({
      search: '',
      status: '',
      form: '',
      city: '',
      date: '', // in case the time_filter = date_range
      display_order: '',
      has_notes: false,
      advisor: 0,
      time_filter: 'this_week',
    })
    let pagination = reactive({
      page: 1,
      totalRecords: 0,
    })

    // Reactive variables
    let bulkSelectedAction = ref('')
    let cancelToken        = false
    let leads              = ref([])
    let loadingData        = ref(false)
    let searchBarActive    = ref(false)
    let selectAll          = ref(false)
    let advisorsForFiltering = ref([])

    let leadNotes = reactive({
      modalOpen: false,
      notes: [],
    })

    // Reactive function
    const bulkAction = async () => {
      let selectedAL_Ids = []
      for(let x in leads.value){
        if( leads.value[x].selected){
          selectedAL_Ids.push( leads.value[x].AL_id)
        }
      }
      if( selectedAL_Ids.length < 1 ){
        return false
      }
      if( bulkSelectedAction.value === 'withdraw'){
        await F_withdrawLeads( selectedAL_Ids )
      }
    }
    const doSearch = (search) => filters.search = search
    const getLeads = async (reset, resetPage) => {

      if(false !== cancelToken){
        cancelToken()
        cancelToken = false
      }
      if(resetPage){
        pagination.page = 1
      }
      loadingData.value = true
      let d = Object.assign({}, filters)
      d.page = pagination.page
      axios.post('/api/admin/leads/assigned', d,
          {
            cancelToken: new axios.CancelToken( c => {
              cancelToken = c
            })
          })
          .then( (resp) => {
            if(reset){
              leads.value = []
            }
            for(let x in resp.data.data){
              resp.data.data[x].selected = false;
              leads.value.push(resp.data.data[x]);
            }
            if(pagination.page === 1){
              pagination.totalRecords = resp.data.total_records
            }
          })
          .catch( err => {
            console.log('Invoices.vue ajax error: ', err)
          })
          .then(() => {
            cancelToken = false
            loadingData.value = false
          })
    }
    const paginationCallback = () => getLeads(true)
    const F_withdrawLeads = async ( AL_ids ) => {
      withdrawLeads(AL_ids, 'lead').then(ids=>{
        updateTracker('admin', ['leads_list'])
        // Remove the selected leads from the leads array
        for(let x in ids){
          for(let y in leads.value){
            if(leads.value[y].AL_id === ids[x]){
              leads.value.splice(y,1)
              break;
            }
          }
        }
        // All deleted, no entry left in the advisor array? and previous pages exists
        if( !leads.value.length && pagination.page > 1 ){
          // Deduct from total records value
          pagination.totalRecords -= ids.length
          // Goto previous page
          pagination.page--
          getLeads(true)
        }
      })
      .catch(()=>{})
    }
    const getLeadNotes = async (id)=> {
      leadNotes.notes = []
      const l = await loadingController.create({message: t('Please wait...')})
      await l.present()
      axios.post('/api/admin/leads/'+id+'/notes',{})
      .then(r=>{
        if(!r.data.success){
          toastController.create({color:'danger', message: r.data.message, position: 'top', duration: 4000}).then((t)=> t.present())
        }
        else{
          leadNotes.modalOpen = true
          leadNotes.notes = r.data.data
        }
      })
      .catch(er=>{
        let msg = er.response ? er.response.status+' '+er.response.statusText : t('errors.general_error')
        toastController.create({color:'danger', message: msg, position: 'top', duration: 4000}).then((t)=> t.present())
      })
      .then(()=>{
        l.dismiss()
      })
    }
    const getAdvisorsForFiltering = ()=> {
      advisorsForFiltering.value.splice(0)
      let params = {with_leads_count: true, date: filters.date, time_filter: filters.time_filter}
      axios.get('/api/admin/advisors/having_assigned_leads', {params: params})
          .then(r=>{
            if(r.data.success){
              for(let x in r.data.data){
                advisorsForFiltering.value.push(r.data.data[x])
              }
              F_advisorsLeadsCount();
            }
          })
          .catch(()=>{})
    }
    const F_advisorsLeadsCount = ()=>{
      let uIds = []
      for(let x in advisorsForFiltering.value){
        uIds.push(advisorsForFiltering.value[x].user_id)
      }
      advisorsLeadsCount(uIds, 'both', filters.date, filters.time_filter, filters.status)
          .then(r=>{
            r.forEach((item)=>{
              for(let x in advisorsForFiltering.value){
                if(parseInt(item.user_id) === parseInt(advisorsForFiltering.value[x].user_id)){
                  advisorsForFiltering.value[x].leads = item.leads
                  break
                }
              }
            })
          }).catch(()=>{})
    }

    // Watchers
    watch( ()=> filters.search, () => getLeads(true))
    watch(selectAll, (n) => {
      for(let x in leads.value){
        leads.value[x].selected = n
      }
    })
    // If a lead gets assigned or is withdrawn or deleted from the lead details page, we need to fetch fresh data
    watch( ()=> store.state.dataChangeTracker.admin.assigned_leads, (n) => {
      if(n){
        updateTracker('admin', ['assigned_leads'], false) // Reset tracker
        getLeads(true)
      }
    })
    watch(()=> filters.time_filter, (n)=>{
      if(n !== 'date_range'){
        filters.date = ''
      }
      getLeads(true, true)
      F_advisorsLeadsCount();
    })
    watch(()=> filters.status, ()=> F_advisorsLeadsCount())

    // Mounted
    onIonViewWillEnter(()=> {
      getLeads(true)
      getAdvisorsForFiltering()
    })

    // Returns
    return {
      advisorsForFiltering,
      bulkAction,
      bulkSelectedAction,
      DOMPurify,
      doSearch,
      filters,
      getLeadNotes,
      getLeads,
      isDesktop,
      leads,
      leadNotes,
      leadStatuses,
      loadingData,
      pagination,
      paginationCallback,
      searchBarActive,
      selectAll,
      F_withdrawLeads,
      vueFlatPickerConfig,
    }

  }
});

</script>
